@import "../../assets/fonts/fonts.scss";

.user-details-webapp {
  display: none;
}
.show-vouchers-webapp {
  display: none;
}
@media (pointer: none), (pointer: coarse) {
  .user-details-webapp {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;

    .user-details-webapp-row-1 {
      display: flex;
      flex-direction: column;

      flex: 0.16;
      justify-content: space-evenly;

      padding-left: 5vw;
      padding-top: 36.3px;
      padding-right: 10px;

      .back-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 5px;
        margin-bottom: 20px;
        .back-arrow {
          height: 15px;
          width: 20px;
          margin-right: 20px;
        }
        .back-text {
          font-family: $font-family-poppins;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height */

          color: #000000;

          opacity: 0.5;
        }
      }

      .user-details-webapp-header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        height: 70px;
        padding-left: 10px;
        margin-bottom: 26px;

        .title {
          font-family: $font-family-poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          margin-bottom: 23px;
        }
        .quote {
          font-family: $font-family-poppins;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;

          color: #000000;

          opacity: 0.4;
        }
      }
    }

    .user-details-webapp-row-2 {
      flex: 0.57;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .user-details-webapp-row-3 {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .view-vouchers-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border: 1.5px solid #417fbb;
        border-radius: 10px;
        flex: 0.8;
        height: 50px;
      }
    }
  }

  .show-vouchers-webapp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
    width: 100vw;
    contain: content;
    overflow-y: auto;
  }
}
