@import "../assets/fonts/fonts.scss";

.transactions-container {
  width: 1038px;
  height: 520px;
  background: rgba(236, 242, 248, 0.4);
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  margin-top: 18px;
  margin-left: 50px;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow: auto;
}

.transaction-title {
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  margin-top: 30px;
  margin-left: 50px;
}

table {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.ant-pagination-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}
.ant-pagination-item-ellipsis {
  display: none;
}
thead > tr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1026px;
  padding: 10px;
}
li {
  list-style-type: none;
  padding: 0;
}
tbody > tr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1010px;
  padding: 10px;
  margin-left: 10px;
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(65, 127, 187, 0.05);
  border-radius: 10px;
  margin-bottom: 10px;
}

thead > tr > th {
  flex: 0.3;
  padding: 10px;
}

tbody > tr > td {
  flex: 0.3;
  padding: 10px;
  inline-size: 150px;
  overflow-wrap: break-word;
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height */

  color: #000000;
}
.ant-pagination-item-active {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: rgb(126, 107, 107);
  border: solid 2px blue;

  width: 30px;
}
.ant-pagination-item {
  cursor: pointer;
}
.transaction-image {
  width: 100px;
  height: 60px;
}
.transaction-pagination {
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  margin-left: 15px;
  margin-top: 25px;
}

@media (pointer: none), (pointer: coarse) {
  .transaction-title {
    font-family: $font-family-poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    margin-top: 0px;
    margin-left: 50px;
  }

  .transactions-container {
    width: 80vw;
    height: 40vh;
    background: rgba(236, 242, 248, 0.4);
    border: 1px solid #f0f0f0;
    border-radius: 10px;
  }
}
