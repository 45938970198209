@import "../assets/fonts/fonts.scss";

.search-user-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-color: black;
  border-width: 5px solid;
}
.title {
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;
  margin-left: 50px;
  margin-top: 30px;
  height: 30px;
  width: 250px;
}

.title-quote {
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: #000000;
  opacity: 0.4;
  margin-left: 50px;
  margin-top: 10px;
}

.search-bar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw;
  margin-top: 30px;
  padding-right: 50px;
  height: 60px;
}

@media (pointer: none), (pointer: coarse) {
  .search-bar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 40vw;
    height: 60px;
  }
}
