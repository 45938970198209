@use "SearchUserWebApp" as searchUserWebApp;

@import "../../assets/fonts/fonts.scss";

@mixin BaseWebppMixin {
  .base-webapp {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    height: 100vh;
    width: 100vw;
    overflow-y: auto;

    .base-webapp-row-1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-top: 30px;
      flex: 0.15;

      .base-webapp-header {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .base-webapp-logo {
          width: 160px;
          height: 80px;
          margin-bottom: 6vh;
        }
      }
    }

    .base-webapp-row-2 {
      display: flex;

      flex-direction: row;
      padding-top: 30px;
      flex: 0.8;
      padding-left: 5vw;

      contain: content;
      overflow-y: auto;
      overflow-x: hidden;

      @include searchUserWebApp.searchUserMixin;
    }

    .base-webapp-row-3 {
      display: flex;
      flex-direction: row;
      padding-top: 30px;
      flex: 0.2;
      padding-left: 3vw;
      padding-right: 3vw;
      background: black;
      justify-content: flex-start;

      .base-webapp-footer-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: black;
        margin-left: 20px;
        flex: 0.4;
        .base-webapp-footer-text {
          font-family: $font-family-poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          /* identical to box height */
          margin-left: 10px;
          color: white;
        }
      }
    }
    @media only screen and (max-width: 360px) {
      .base-webapp-row-3 {
        display: flex;

        flex-direction: column;
        padding-top: 30px;
        flex: 0.15;
        padding-left: 0px;
        background: white;
        justify-content: flex-start;

        .base-webapp-footer-row {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          flex: 0.4;
          .base-webapp-footer-text {
            font-family: $font-family-poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height */
            margin-left: 10px;
            color: #ffffff;
          }
        }
      }
    }
  }
}
