//user details
@import "../assets/fonts/fonts.scss";

.user-details-box {
  width: 493px;
  height: fit-content;
  background: rgba(236, 242, 248, 0.4);
  border: 1px solid #f0f0f0;
  border-radius: 10px;
}

.user-details-title {
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  margin-top: 30px;
  margin-left: 30px;
  color: #000000;
}

.user-details-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.user-details-content-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 30px;
  padding-bottom: 20px;
  padding-top: 30px;
}

.user-detail-row-header {
  flex: 0.4;
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.user-detail-row-values {
  flex: 0.6;
  margin-right: 10px;

  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  inline-size: 150px;
  overflow-wrap: break-word;

  color: rgba(0, 0, 0, 0.5);
}

.user-details-content-text-2 {
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: rgb(0, 0, 0, 0.5);
}

.user-details-webapp-qrcode {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 50px;
  margin-bottom: 10px;
}

//@media only screen and (max-width: 1000px)
@media (pointer: none), (pointer: coarse) {
  .user-details-title {
    display: none;
  }
  .user-details-webapp-qrcode {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 50px;
    margin-bottom: 10px;
  }

  .user-details-content-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
