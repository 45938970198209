@import "../assets/fonts/fonts.scss";

.details-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  width: 80px;
  margin-top: 46px;
  margin-left: 60px;

  //title text style
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.details-back-arrow {
  height: 18px;
  width: 18px;
  margin-top: 5px;
  cursor: pointer;
}

.details-body {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  margin-top: 34px;
  padding-left: 50px;
}

@media (pointer: none), (pointer: coarse) {
  .user-details-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 85vw;
    height: fit-content;
    contain: content;
    overflow: auto;
  }
}
