@import "../assets/fonts/fonts.scss";

.login-column-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  overflow-x: auto;
}
.login-column-1 {
  flex: 0.5;
}
.login-column-2 {
  flex: 0.5;
}

// Right half

.login-component-container {
  max-width: 683px;
  max-height: 768px;
}

//logo div
.logo-container {
  font-family: $font-family-poppins;
  font-size: 30px;
  font-weight: bolder;
  font-style: normal;
  margin-top: 100px;
  margin-left: 140px;
  width: 30vw;
  display: flex;
  flex-direction: column;
}
.logo-image {
  max-width: 177px;
  max-height: 74px;
  margin-bottom: 30px;
}
.logo-text {
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #000000;

  opacity: 0.4;
  word-wrap: break-word;
}

//Login input box
.login-box-container {
  display: flex;
  margin-top: 70px;

  height: 154px;
  flex-direction: column;
  justify-content: space-around;
}

.input-container {
  border: 1px solid black;
  width: 32vw;
  height: 64px;
  contain: content;
  display: flex;
  justify-content: flex-start;
  border-radius: 10px;
}
.input-container-logo {
  margin-top: 20px;
  margin-left: 40px;
  margin-right: 40px;
  height: 24px;
  width: 24px;
  margin-bottom: 20px;
}

.login-input {
  background: white;
  background: rgba(0, 0, 0, 0);
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  border: none;
  padding-left: 20px;
  outline: none;
}
.login-input:-webkit-autofill,
login-input:-webkit-autofill:hover,
login-input:-webkit-autofill:focus,
login-input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

//submit button
.submit {
  border: 1px solid #d1d1d1;
  margin-top: 70px;
  width: 32vw;
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: linear-gradient(black, #8c8c8c);

  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 30px;
  /* identical to box height */
  color: white;
  text-align: center;
  cursor: pointer;
}
.input-submit {
  border: 1px solid #d1d1d1 !important;
  margin-top: 70px;
  width: 32vw;
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: linear-gradient(black, #8c8c8c) !important;

  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 30px;
  /* identical to box height */
  color: white !important;
  text-align: center;
  cursor: pointer;
}
.input-submit:hover {
  margin-top: 70px;

  width: 32vw;
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: linear-gradient(#8c8c8c, black) !important;

  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 30px;
  /* identical to box height */
  color: white !important;
  text-align: center;
  cursor: pointer;
}

.copyright {
  font-family: $font-family-Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  margin-left: 2vw;
  word-wrap: break-word;
  margin-top: 150px;
  width: 483px;
  height: 64px;
  color: #000000;
}

.display_error {
  margin-top: 10px;
  font-style: normal;
  color: red;
  font-size: 16px;
  font-weight: normal;
  bottom: 30px;
}

.login-webapp {
  display: none;
}

@media (pointer: none), (pointer: coarse) {
  .login-column-container {
    display: none;
  }

  .login-column-1 {
    display: none;
  }
  .login-column-2 {
    display: none;
  }

  .login-webapp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;

    .login-webapp-row-1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-top: 30px;
      flex: 0.3;

      .login-webapp-header {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .login-webapp-logo {
          width: 160px;
          height: 80px;
          margin-bottom: 6vh;
        }
        .login-header-text {
          font-family: $font-family-poppins;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 22px;
          text-align: center;
          color: rgba(0, 0, 0, 0.4);
        }
      }
    }

    .login-webapp-row-2 {
      display: flex;
      flex-direction: column;
      flex: 0.2;
      contain: content;
      align-items: center;

      .login-webapp-input-container {
        flex-direction: column;
        justify-content: center;
        padding-top: 30px;
        padding-left: 20px;
        width: 375px;
        .input-container {
          border: 1px solid black;
          width: 335px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          border-radius: 10px;
        }

        .login-input {
          background: white;
          background: rgba(0, 0, 0, 0);
          font-family: $font-family-poppins;
          font-style: normal;
          flex: 1;
          font-weight: 400;
          font-size: 17px;
          line-height: 20px;
          border: none;

          padding-left: 20px;
          outline: none;
        }
      }
      .login-webapp-input-submit {
        border: 1px solid #d1d1d1;
        width: 335px;
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: linear-gradient(black, #8c8c8c);

        font-family: $font-family-poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        /* identical to box height */
        color: white;
        text-align: center;
        cursor: pointer;
      }
    }
    .login-webapp-row-3 {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      padding-bottom: 20px;
      justify-content: center;
      flex: 0.5;
    }
  }
}
