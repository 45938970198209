@use "./webapp/BaseWebApp" as BaseWebApp;
@use "./webapp/UserDetailsWebApp" as userDetailsWebApp;

@import "../assets/fonts/fonts.scss";

.base-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
}
.children-component {
  padding-top: 130px;
  //border: 1px solid black;
}

.user-profile-row {
  height: 80px;
  width: 100vw;
  background: rgba(65, 117, 187, 0.1);
  border: 1px solid #f0f0f0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: row;
  padding-left: 70vw;
}

.profile-info {
  color: black;

  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-info-name {
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #272727;
}

.side-bar {
  width: 248px;

  display: flex;
  flex-direction: column;

  background: white;
  border: 1px solid #979797;
}

.side-bar-logo {
  height: 150px;
  width: 245px;
  //margin-left: 60px;
  //margin-top: 40px;
}

.side-bar-options-box {
  height: 100vh;
  width: 248px;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  //border: 1px solid #979797;
  // background: white;
}

.side-bar-row {
  display: flex;
  width: 250px;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 20px;
  margin-right: 30px;
  //margin-left: 40px;
  cursor: pointer;
}
.side-bar-row-arrow {
  width: 7px;
  height: 10px;
  margin-top: 7px;
}

.side-bar-row-text {
  font-family: $font-family-DinProRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 22px;
  /* identical to box height */

  color: black;
  margin: 5px 0 10px 20px;
  margin-right: 30px;
  height: 10px;
  width: 80%;
  letter-spacing: 0;
  padding: 20px 0 20px 0;
  border-radius: 24px;
  border: 1px solid black;
  background-color: #ffffff;
  outline: none;
  display: flex;
  align-items: center;
  padding-left: 7%;
}

.side-bar-row-text:hover {
  background-color: #cccccc;
  border: 1px solid #979797;
  text-decoration: none;
  outline: none;
}
.side-bar-row-text:target {
  background-color: #000;
  border: 1px solid #979797;
  text-decoration: none;
  outline: none;
}
.side-bar-logout {
  display: flex;
  width: 250px;
  flex-direction: row;
  justify-content: space-evenly;
  margin-right: 30px;
  padding-right: 28px;
  // margin-left: 20px;
  margin-top: 60vh;
  margin-bottom: 30px;
  cursor: pointer;
}
.side-bar-logout-text {
  font-family: $font-family-DinProRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 22px;
  /* identical to box height */

  color: black;
  margin: 0 0 10px 40px;
  margin-right: 35px;
  height: 10px;
  width: 80%;
  letter-spacing: 0;
  padding: 20px 0 20px 0;
  border-radius: 24px;
  border: 1px solid black;
  background-color: #ffffff;
  outline: none;
  display: flex;
  align-items: center;
  padding-left: 7%;
}

.side-bar-logout-text:hover {
  background-color: #cccccc;
  border: 1px solid #979797;
  text-decoration: none;
  outline: none;
}
.side-bar-logout-text:target {
  background-color: #000;
  border: 1px solid #979797;
  text-decoration: none;
  outline: none;
}

//all web app class names are not be displayed for larger screen
.base-webapp {
  display: none;
}
.user-details-webapp {
  display: none;
}

@media (pointer: none), (pointer: coarse) {
  div.base-webapp-row-2 {
    margin: -5vw;
    padding-right: 10vw;
    overflow: auto;
    background: white;
    padding-bottom: 100px;
  }

  ul#results.list-group {
    background: blue;
  }

  div.result-row {
    width: 70vw;
    background: rgba(236, 242, 248, 0.4);
  }
}
@media (pointer: none), (pointer: coarse) {
  .base-container {
    display: none;
  }

  @include BaseWebApp.BaseWebppMixin;
}

.ant-message-custom-content {
  display: flex;
  justify-content: center;
  align-items: center;
}