//Available vouchers

@import "../assets/fonts/fonts.scss";

.available-vocuhers-box {
  width: 493px;
  height: 541px;
  background: rgba(236, 242, 248, 0.4);
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  margin-left: 50px;
  overflow-y: scroll;
}

.voucher-details-title {
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  margin-bottom: 1rem;
  margin-left: 2rem;
  color: #000000;
}

.voucher-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 130px;
  width: 433px;
  background: white;
  margin-left: 20px;
  margin-top: 20px;
  border-radius: 10px;
}

.voucher-details-column1 {
  padding-left: 30px;
  padding-top: 15px;
  flex: 0.35;
}
.voucher-details-column2 {
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  flex: 0.65;
}
.voucher-row-title {
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}
.voucher-redeem-code {
  margin-top: 30px;
  margin-bottom: 20px;
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 20px;
  color: black;
}
.voucher-redeem-valid {
  margin-top: 10px;
  // margin-bottom: 10px;
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
}
.voucher-redeem-series {
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 400;
}

.voucher-redeem-button,
.voucher-redeem-button:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: black;
  border: 1px solid black;
  border-radius: 6px;
  height: 34px;
  width: 5rem;
  cursor: pointer;
}

.voucher-redeem-button-text {
  font-family: $font-family-poppins;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}
@media (pointer: none), (pointer: coarse) {
  .voucher-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 130px;
    width: 80vw;
    background: rgba(236, 242, 248, 0.4);
    overflow-x: hidden;
    margin-left: 30px;
    border-radius: 10px;
    border: 1px gray solid;
  }

  .voucher-redeem-button,
  .voucher-redeem-button:hover {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: black;
    border: 1px solid black;
    border-radius: 6px;
    height: 34px;
    width: 5rem;
    cursor: pointer;
  }

  .voucher-issue-webapp-col-3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-inline: 1rem;
  }
}
