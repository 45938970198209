@mixin searchUserMixin {
  .search-button {
    display: none;
  }
  .search-user-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .search-bar-dropdown {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 60vw;
    height: 60px;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
  }

  #results {
    width: 60vw;
    background: rgba(236, 242, 248, 0.4);
    margin-bottom: 30px;
    border-radius: 10px;
    /* border-bottom: 1px #eeeeee solid; */
  }
}
