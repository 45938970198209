@import "../assets/fonts/fonts.scss";

.search-bar-dropdown {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 769px;
  height: 60px;
  margin-left: 50px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
}
.search-bar-dropdown:hover {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 769px;
  height: 60px;
  margin-left: 50px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
}

.search-bar-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1000px;
}

.search-icon {
  width: 16px;
  height: 16px;
  margin-top: 15.85px;
  margin-left: 21.95px;
}
.search-icon-2 {
  width: 12px;
  height: 12px;
  margin-left: 21.95px;
  margin-right: 5vw;
}
.result-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: solid 1px white;
  border-radius: 10px;
  cursor: pointer;
}

.result-row-button {
  background: rgba(236, 242, 248, 0.2);
  border: none;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
}
.result-row-diagonal-arrow {
  margin-right: 27px;
  width: 15px;
  height: 15px;
  margin-top: 10px;
}
.Modal-Redeem {
}

#results {
  width: 796px;
  margin-left: 50px;
  margin-top: 10px;
  background: rgba(236, 242, 248, 0.4);

  border-radius: 10px;
  /* border-bottom: 1px #eeeeee solid; */
}

#results > button {
  background-color: #fafafa;
  border: none;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #eeeeee;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#search-bar {
  width: inherit;
  margin-left: 21px;
  outline: none;
  border: none;
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 22px;
}

.search-button {
  background: black;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 60px;
  margin-left: 30px;
  cursor: pointer;

  //Search Button text
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
}

.search-button-mobile {
  font-weight: 500;
  border-radius: 0.5rem;
  border-width: 2;
  border-color: black;
  background: black;
  font-family: $font-family-poppins;
  color: #ffffff;
  align-self: center;
  justify-content: center;
}
.search-button-mobile:hover {
  font-weight: 500;
  background: black;
  border-width: 2;
  border-color: black;
  border-radius: 0.5rem;
  font-family: $font-family-poppins;
  color: #ffffff;
}
.Validation-text {
  margin-top: 30px;
  font-size: 16px;
  color: red;
  align-self: center;
  text-align: center;
}
.scan-qr {
  display: none;
}
.search-bar-webapp-row {
  display: none;
}

// @media only screen and (max-width: 1000px) {
@media (pointer: none), (pointer: coarse) {
  .search-bar-row-container {
    display: none;
  }

  .search-bar-webapp-row {
    display: flex;
  }

  #search-bar {
    width: 50vw;
    margin-left: 0px;
    padding-bottom: 30px;
    border: none;
    font-family: $font-family-poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
  }
  #search-bar:focus {
    border: none;
    outline: none;
  }

  #results.list-group {
    margin-left: 50px;
    margin-top: 10px;
    background: rgba(236, 242, 248, 0.4);
    margin-bottom: 30px;
    border-radius: 10px;
    /* border-bottom: 1px #eeeeee solid; */
  }
  .spinner-wrapper {
    background-color: rgba(
      255,
      255,
      255,
      0.5
    ); /* set background color with alpha transparency */
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    //height: 100%; /* set the height to 100% to make it fill the entire container */
  }
  .ant-spin-container {
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    background-color: transparent !important; /* set the background color of the spinner container to transparent */
  }
}
